import logo from './logo.svg';
import './App.css';

import 'bootstrap-icons/font/bootstrap-icons.css';
import Sidebar from './Components/Sidebar/Sidebar';

import "font-awesome/css/font-awesome.min.css";

import Mains from './Components/common/Pages/mains';

function App() {
  return (
    <div className="">
  
      <Sidebar/>
<Mains/>
    </div>
  );
}

export default App;
