import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AddNews from './news/AddNewsForm';
import NewsTable from './news/News';
import AddCategory from './Category/AddCategory';
import Category from './Category/Category';
import AddAuthor from './Author/AddAuthor';
import Author from './Author/Author';
import LearningCenter from './LearningCenter/LearningCenter';
import AddLearningCenter from './LearningCenter/AddLearningCenter';

import Addcrypto_resources_directory from './Crypto_resources_directory/Addcrypto_resources_directory';
import Crypto_resources_directory from './Crypto_resources_directory/Crypto_resources_directory';




const DashboardMain = () => {
  return (
    <main className="dashboard-main">
      <div className="navbar-header">
        {/* Add your navbar content here */}
      </div>

      <div className="dashboard-main-body">
  
      <Routes>
          <Route path="/AddNews" element={<AddNews />} />
          <Route path="/News" element={<NewsTable />} />
          <Route path="/addCategory" element={<AddCategory />} />
          <Route path="/Category" element={<Category />} />
          <Route path="/AddAuthor" element={<AddAuthor />} />
          <Route path="/Author" element={<Author />} />
          <Route path="/LearningCenter" element={<LearningCenter />} />
          <Route path="/AddLearningCenter" element={<AddLearningCenter />} />

          <Route path="/Addcrypto_resources_directory" element={<Addcrypto_resources_directory />} />
          <Route path="/Crypto_resources_directory" element={<Crypto_resources_directory />} />
    
        </Routes>

      </div>

      <footer className="d-footer">
        <div className="row align-items-center justify-content-between">
          <div className="col-auto">
            <p className="mb-0">© 2024 WowDash. All Rights Reserved.</p>
          </div>
          <div className="col-auto">
            <p className="mb-0">
              Made by <span className="text-primary-600">wowtheme7</span>
            </p>
          </div>
        </div>
      </footer>
    </main>
  );
};

export default DashboardMain;
