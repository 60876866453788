import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsTable.css'; // Importing the CSS file

function NewsTable() {
    const [news, setNews] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [itemsPerPage] = useState(5); // State for items per page

    useEffect(() => {
        fetchNews();
    }, []);

    // Fetch news articles from the backend
    const fetchNews = async () => {
        try {
            const response = await axios.get('https://dailynewscrypto.net/apiad.php?endpoint=news',{
                cache: 'no-store',  // Disable cache
              }); // Updated URL
            setNews(response.data);
        } catch (error) {
            console.error('Error fetching news:', error);
        }
    };

  
    // Delete a news article by ID
    const deleteNews = async (id) => {
        try {
            console.log({id}); // Log the ID to ensure it's correct
            const response = await axios.get(`https://dailynewscrypto.net/apiad.php?endpoint=delete-news&id=${id}`);
            
            // Check if the response was successful
            if (response.status === 200) {
                alert('News article deleted successfully!');
                fetchNews(); // Refresh the list after successful deletion
            } else {
                // Handle unsuccessful deletion, if necessary
                alert(response);
            }
        } catch (error) {
            // Improved error handling
            console.error('Error deleting news:', error);
            alert('An error occurred while deleting the news article.');
        }
        
        
        
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    // Filter the news based on the search query
    const filteredNews = news.filter((item) => 
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
        item.author.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Pagination Logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentNews = filteredNews.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredNews.length / itemsPerPage);

    return (
        <div>
            <h1>News Articles</h1>
            <div className='d-flex'>
                <input
                    type="text"
                    placeholder="Search by title or author"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <a className='btn-primary bg-primary text-white' id='addbttn' href='./addnews'>
                    Add News
                </a>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Author</th>
                        <th>Published Date</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentNews.map((item) => (
                        <tr key={item.news_id}>
                            <td>{item.news_id}</td>
                            <td>{item.title}</td>
                            <td>{item.author}</td>
                            <td>{item.published_date}</td>
                            <td>
                                <button onClick={() => deleteNews(item.news_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}>
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1} 
                        onClick={() => paginate(index + 1)} 
                        className={index + 1 === currentPage ? 'active' : ''}>
                        {index + 1}
                    </button>
                ))}
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default NewsTable;
