import React, { useState } from "react";
import axios from "axios";
import { Editor } from "@tinymce/tinymce-react";
import './CryptoResourceForm.css'; // Import the styles

const CryptoResourceForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    content: "",
    image: null,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleFileChange = (e) => {
    setFormData({
      ...formData,
      image: e.target.files[0],
    });
  };

  const handleEditorChange = (content, editor) => {
    setFormData({
      ...formData,
      content: content, // Update the content state when the editor changes
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const form = new FormData();
    form.append("title", formData.title);
    form.append("content", formData.content);
    form.append("image", formData.image);

    try {
      const response = await axios.post("https://dailynewscrypto.net/apiad.php?endpoint=add-resources", form, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      alert("Resource added successfully!");
      setFormData({ title: "", content: "", image: null });
    } catch (error) {
      console.error("Error adding resource:", error);
      alert("Failed to add resource. Please try again.");
    }
  };

  return (
    <div id="crypto-resource-form">
      <h6>Add Crypto Resource</h6>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="form-group">
          <label htmlFor="title">Title</label>
          <input
            type="text"
            id="title"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
            className="form-control"
          />
        </div>
        <div className="form-group">
          <label htmlFor="content">Content</label>
          <Editor
            apiKey="p87bqupnlkwoj9v3rza42jhmsmkm228kibinq86ztoy1un55"  // API key for TinyMCE
            value={formData.content}
            onEditorChange={handleEditorChange}  // Update the formData when content changes
            init={{
              height: 300,
              menubar: false,
              plugins: [
                "advlist",
                "autolink",
                "lists",
                "link",
                "image",
                "charmap",
                "print",
                "preview",
                "searchreplace",
                "wordcount",
              ],
              toolbar:
                "undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | link image | preview",
            }}
          />
        </div>
        <div className="form-group">
          <label htmlFor="image">Upload Image</label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleFileChange}
            required
            className="form-control"
          />
        </div>
        <button type="submit" className="btn btn-primary mt-3">
          Submit
        </button>
      </form>
    </div>
  );
};

export default CryptoResourceForm;
