import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsTable.css'; // Importing the CSS file

function AuthorTable() {
    const [authors, setAuthors] = useState([]);  // State for authors
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [itemsPerPage] = useState(5); // Number of items per page

    useEffect(() => {
        fetchAuthors();  // Fetch authors when component mounts
    }, []);

    // Fetch author data from the backend
    const fetchAuthors = async () => {
        try {
            const response = await axios.get('https://dailynewscrypto.net/apiad.php?endpoint=authors',{
                cache: 'no-store',  // Disable cache
              });
            setAuthors(response.data);
        } catch (error) {
            console.error('Error fetching authors:', error);
        }
    };

    // Delete an author by ID
    const deleteAuthor = async (id) => {
        try {
            axios.get(`https://dailynewscrypto.net/apiad.php?endpoint=delete-author&id=${id}`);
            alert('Author deleted successfully!');
            fetchAuthors(); // Refresh the list after deletion
        } catch (error) {
            console.error('Error deleting author:', error);
        }
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);  // Reset to the first page when search query changes
    };

    // Filter authors based on the search query
    const filteredAuthors = authors.filter((author) => 
        author.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Get the current items to display based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentAuthors = filteredAuthors.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredAuthors.length / itemsPerPage);

    return (
        <div>
            <h1>Authors</h1>
            <div className='d-flex'>
                <input
                    type="text"
                    placeholder="Search by author name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <a className='btn-primary bg-primary text-white' id='addbttn' href='./addauthor'>
                    Add Author
                </a>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Author Name</th>
                        <th>Email</th>
                        <th>Bio</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentAuthors.map((author) => (
                        <tr key={author.author_id}>
                            <td>{author.author_id}</td>
                            <td>{author.name}</td>
                            <td>{author.email}</td>
                            <td>{author.bio}</td>
                            <td>
                                <button onClick={() => deleteAuthor(author.author_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}>
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1} 
                        onClick={() => paginate(index + 1)} 
                        className={index + 1 === currentPage ? 'active' : ''}>
                        {index + 1}
                    </button>
                ))}
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default AuthorTable;
