import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsTable.css'; // Importing the CSS file

function CryptoResourcesTable() {
    const [cryptoResources, setCryptoResources] = useState([]);
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [currentPage, setCurrentPage] = useState(1); // State for current page
    const [itemsPerPage] = useState(5); // State for items per page
    const [showFullDescription, setShowFullDescription] = useState(null); // State to track which description is expanded

    useEffect(() => {
        fetchCryptoResources();
    }, []);

    // Fetch CryptoResources data from the backend
    const fetchCryptoResources = async () => {
        try {
            const response = await axios.get('https://dailynewscrypto.net/apiad.php?endpoint=resources',{
                cache: 'no-store',  // Disable cache
              }); // Updated URL
            // Sort data from new to old based on created_at
            const sortedData = response.data.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
            setCryptoResources(sortedData);
        } catch (error) {
            console.error('Error fetching Crypto Resources:', error);
        }
    };

    // Delete a CryptoResource article by ID
    const deleteCryptoResource = async (id) => {
        try {
            await axios.get(`https://dailynewscrypto.net/apiad.php?endpoint=delete-resources&id=${id}`); // Updated URL
            alert('Crypto Resource deleted successfully!');
            fetchCryptoResources(); // Refresh the list
        } catch (error) {
            console.error('Error deleting Crypto Resource:', error);
        }
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1); // Reset to first page when search query changes
    };

    // Filter the CryptoResources data based on the search query
    const filteredCryptoResources = cryptoResources.filter((item) => 
        item.title.toLowerCase().includes(searchQuery.toLowerCase()) || 
        item.content.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Truncate description to a short version
    const truncateDescription = (description, length = 100) => {
        if (description.length > length) {
            return description.substring(0, length) + '...'; // Truncate and add ellipsis
        }
        return description;
    };

    // Toggle full description visibility
    const toggleDescription = (id) => {
        if (showFullDescription === id) {
            setShowFullDescription(null); // If already expanded, collapse it
        } else {
            setShowFullDescription(id); // Otherwise, expand the description
        }
    };

    // Pagination Logic
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCryptoResources = filteredCryptoResources.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredCryptoResources.length / itemsPerPage);

    return (
        <div>
            <h6>Crypto Resources Directory</h6>
            <div className='d-flex'>
                <input
                    type="text"
                    placeholder="Search by title or content"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <a className='btn-primary bg-primary text-white' id='addbttn' href='./Addcrypto_resources_directory'>
                    Add Crypto Resource
                </a>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Title</th>
                        <th>Content</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCryptoResources.map((item) => (
                        <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.title}</td>
                            <td>
                                {showFullDescription === item.id 
                                    ? item.content  // Show full content
                                    : truncateDescription(item.content)} {/* Truncated content */}
                                <button onClick={() => toggleDescription(item.id)}>
                                    {showFullDescription === item.id ? 'Show Less' : 'Show More'}
                                </button>
                            </td>
                            <td>
                                <button onClick={() => deleteCryptoResource(item.id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}>
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1} 
                        onClick={() => paginate(index + 1)} 
                        className={index + 1 === currentPage ? 'active' : ''}>
                        {index + 1}
                    </button>
                ))}
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default CryptoResourcesTable;
