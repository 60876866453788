import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './NewsTable.css'; // Importing the CSS file

function CategoryTable() {
    const [categories, setCategories] = useState([]);  // State for categories
    const [searchQuery, setSearchQuery] = useState(''); // State for search query
    const [currentPage, setCurrentPage] = useState(1); // Track current page
    const [itemsPerPage] = useState(5); // Number of items per page

    useEffect(() => {
        fetchCategories();  // Fetch categories when component mounts
    }, []);

    // Fetch category data from the backend
    const fetchCategories = async () => {
        try {
            const response = await axios.get('https://dailynewscrypto.net/apiad.php?endpoint=categories');
            setCategories(response.data);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };

    // Delete a category by ID
    const deleteCategory = async (id) => {
        try {
            axios.get(`https://dailynewscrypto.net/apiad.php?endpoint=delete-category&id=${id}`);
            alert('Category deleted successfully!');
            fetchCategories(); // Refresh the list
        } catch (error) {
            console.error('Error deleting category:', error);
        }
    };

    // Handle search query change
    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value);
        setCurrentPage(1);  // Reset to the first page when search query changes
    };

    // Filter categories based on the search query
    const filteredCategories = categories.filter((category) => 
        category.name.toLowerCase().includes(searchQuery.toLowerCase())
    );

    // Get the current items to display based on pagination
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentCategories = filteredCategories.slice(indexOfFirstItem, indexOfLastItem);

    // Handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const totalPages = Math.ceil(filteredCategories.length / itemsPerPage);

    return (
        <div>
            <h1>Categories</h1>
            <div className='d-flex'>
                <input
                    type="text"
                    placeholder="Search by category name"
                    value={searchQuery}
                    onChange={handleSearchChange}
                    className="search-input"
                />
                <a className='btn-primary bg-primary text-white' id='addbttn' href='./addcategory'>
                    Add Category
                </a>
            </div>

            <table>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Category Name</th>
                        <th>Description</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {currentCategories.map((category) => (
                        <tr key={category.category_id}>
                            <td>{category.category_id}</td>
                            <td>{category.name}</td>
                            <td>{category.description}</td>
                            <td>
                                <button onClick={() => deleteCategory(category.category_id)}>Delete</button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            {/* Pagination Controls */}
            <div className="pagination">
                <button 
                    onClick={() => paginate(currentPage - 1)} 
                    disabled={currentPage === 1}>
                    Previous
                </button>
                {Array.from({ length: totalPages }, (_, index) => (
                    <button 
                        key={index + 1} 
                        onClick={() => paginate(index + 1)} 
                        className={index + 1 === currentPage ? 'active' : ''}>
                        {index + 1}
                    </button>
                ))}
                <button 
                    onClick={() => paginate(currentPage + 1)} 
                    disabled={currentPage === totalPages}>
                    Next
                </button>
            </div>
        </div>
    );
}

export default CategoryTable;
