import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Editor } from '@tinymce/tinymce-react';

const AddNews = () => {
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [authorId, setAuthorId] = useState('');
  const [categoryId, setCategoryId] = useState('');
  const [sourceUrl, setSourceUrl] = useState('');
  const [image, setImage] = useState(null);
  const [authors, setAuthors] = useState([]);
  const [categories, setCategories] = useState([]);
  const [alert, setAlert] = useState({ show: false, type: '', message: '' });

  useEffect(() => {
    axios.get('https://dailynewscrypto.net/apiad.php?endpoint=authors')
      .then(response => setAuthors(response.data))
      .catch(error => console.error('Error fetching authors:', error));

    axios.get('https://dailynewscrypto.net/apiad.php?endpoint=categories')
      .then(response => setCategories(response.data))
      .catch(error => console.error('Error fetching categories:', error));
  }, []);

  const handleEditorChange = (content) => setContent(content);

  const handleImageUpload = (e) => setImage(e.target.files[0]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    formData.append('author_id', authorId);
    formData.append('category_id', categoryId);
    formData.append('source_url', sourceUrl);
    formData.append('published_date', new Date().toISOString());
    if (image) formData.append('image', image);

    try {
      await axios.post('https://dailynewscrypto.net/apiad.php?endpoint=add-news', formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      setAlert({ show: true, type: 'success', message: 'News article added successfully!' });
      setTitle('');
      setContent('');
      setAuthorId('');
      setCategoryId('');
      setSourceUrl('');
      setImage(null);
    } catch (error) {
      console.error('Error adding news:', error);
      setAlert({ show: true, type: 'error', message: 'Failed to add news article.' });
    }
  };

  return (
    <div className="p-8 bg-white rounded-lg">
      <h2 className="text-3xl font-semibold text-center text-gray-800 mb-6">Add News Article</h2>

      {/* Alert */}
      {alert.show && (
  <div
    className={`alert ${
      alert.type === 'success' ? 'alert-success' : 'alert-danger'
    } text-center`}
    role="alert"
  >
    {alert.message}
  </div>
)}


      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label className="block text-sm font-medium text-gray-700">Title:</label>
          <input
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
            className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Content:</label>
          <Editor
        apiKey="p87bqupnlkwoj9v3rza42jhmsmkm228kibinq86ztoy1un55" 
            value={content}
            onEditorChange={handleEditorChange}
            init={{
              height: 300,
              menubar: false,
              plugins: ['advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'wordcount'],
              toolbar: 'undo redo | formatselect | bold italic | alignleft aligncenter alignright | bullist numlist | link image',
            }}
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Author:</label>
          <select
            value={authorId}
            onChange={(e) => setAuthorId(e.target.value)}
            required
            className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Author</option>
            {authors.map((author) => (
              <option key={author.author_id} value={author.author_id}>
                {author.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Category:</label>
          <select
            value={categoryId}
            onChange={(e) => setCategoryId(e.target.value)}
            required
            className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Select Category</option>
            {categories.map((category) => (
              <option key={category.category_id} value={category.category_id}>
                {category.name}
              </option>
            ))}
          </select>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Source URL:</label>
          <input
            type="url"
            value={sourceUrl}
            onChange={(e) => setSourceUrl(e.target.value)}
            className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Image:</label>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageUpload}
            className="mt-2 block w-full px-4 py-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <div className="text-center">
          <button
            type="submit"
            className="bg-blue-600 text-white py-3 px-8 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Add News
          </button>
        </div>
      </form>
    </div>
  );
};

export default AddNews;
