import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "./Sidebar.css"
import { FaBars, FaHome, FaRegNewspaper } from "react-icons/fa"; // Import React Icons

const Sidebar = () => {
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks which dropdown is open
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Track if the mobile menu is open

  const toggleDropdown = (menu) => {
    setOpenDropdown((prev) => (prev === menu ? null : menu));
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev); // Toggle sidebar visibility on mobile
  };

  return (
    <>
      {/* Mobile Menu Button */}
      <button
        className="btn d-block d-md-none d-flex align-items-center"
        type="button"
        onClick={toggleMobileMenu}
      >
        <FaBars className="me-2" /> {/* React Icon for menu */}
      
      </button>

      {/* Sidebar */}
      <aside
        className={`sidebar position-fixed top-0 start-0 h-100   p-3 ${
          isMobileMenuOpen ? "d-block" : "d-none d-md-block"
        }`}
        style={{ width: "250px" }}
      >
        {/* Sidebar Close Button (only visible on mobile) */}
        <button
          type="button"
          className="btn d-md-none position-absolute top-0 end-0 m-2"
          onClick={toggleMobileMenu}
        >
          <Icon icon="radix-icons:cross-2" />
        </button>

        {/* Sidebar Logo */}
        <div>
          <a href="index.html" className="sidebar-logo text-center">
            <h6>Admin Panel</h6>
          </a>
        </div>

        {/* Sidebar Menu Area */}
        <div className="sidebar-menu-area">
          <ul className="sidebar-menu list-unstyled">
            {/* Dashboard Section */}
            <li className="">
              <a
                href="/"
                className="d-flex align-items-center "
                onClick={() => toggleDropdown("dashboard")}
              >
                <Icon
                  icon="solar:home-smile-angle-outline"
                  className="menu-icon me-2"
                />
                <span>Home</span>
              </a>
             
            </li>

            {/* News Section */}
            <li className="dropdown">
  <a
    href="#!"
    className="d-flex align-items-center "
    onClick={() => toggleDropdown("news")}
  >
    <Icon
      icon="material-symbols:article-outline"
      className="menu-icon me-2"
    />
    <span>News</span>
  </a>
  <ul
    className={`sidebar-submenu list-unstyled ms-3 ${
      openDropdown === "news" ? "d-block" : "d-none"
    }`}
  >
    <li>
      <a href="/news" className="d-flex align-items-center">
        <Icon icon="ic:outline-view-list" className="submenu-icon me-2" />
        View All News
      </a>
    </li>
    <li>
      <a href="/addnews" className="d-flex align-items-center">
        <Icon icon="mdi:plus-box-outline" className="submenu-icon me-2" />
        Add News
      </a>
    </li>
  </ul>
</li>


<li className="dropdown">
  <a
    href="#!"
    className="d-flex align-items-center "
    onClick={() => toggleDropdown("Category")}
  >
    <Icon
      icon="material-symbols:article-outline"
      className="menu-icon me-2"
    />
    <span>Category</span>
  </a>
  <ul
    className={`sidebar-submenu list-unstyled ms-3 ${
      openDropdown === "Category" ? "d-block" : "d-none"
    }`}
  >
    <li>
      <a href="/Category" className="d-flex align-items-center">
        <Icon icon="ic:outline-view-list" className="submenu-icon me-2" />
        View All Category
      </a>
    </li>
    <li>
      <a href="/addCategory" className="d-flex align-items-center">
        <Icon icon="mdi:plus-box-outline" className="submenu-icon me-2" />
        Add Category
      </a>
    </li>
  </ul>
</li>


<li className="dropdown">
  <a
    href="#!"
    className="d-flex align-items-center "
    onClick={() => toggleDropdown("Author")}
  >
    <Icon
      icon="material-symbols:article-outline"
      className="menu-icon me-2"
    />
    <span>Authors</span>
  </a>
  <ul
    className={`sidebar-submenu list-unstyled ms-3 ${
      openDropdown === "Author" ? "d-block" : "d-none"
    }`}
  >
    <li>
      <a href="/Author" className="d-flex align-items-center">
        <Icon icon="ic:outline-view-list" className="submenu-icon me-2" />
        View All Author
      </a>
    </li>
    <li>
      <a href="/addAuthor" className="d-flex align-items-center">
        <Icon icon="mdi:plus-box-outline" className="submenu-icon me-2" />
        Add Author
      </a>
    </li>
  </ul>
</li>
<li className="dropdown">
  <a
    href="#!"
    className="d-flex align-items-center "
    onClick={() => toggleDropdown("LearningCenter")}
  >
    <Icon
      icon="material-symbols:article-outline"
      className="menu-icon me-2"
    />
    <span>Learning Center</span>
  </a>
  <ul
    className={`sidebar-submenu list-unstyled ms-3 ${
      openDropdown === "LearningCenter" ? "d-block" : "d-none"
    }`}
  >
    <li>
      <a href="/LearningCenter" className="d-flex align-items-center">
        <Icon icon="ic:outline-view-list" className="submenu-icon me-2" />
        View All Learning Center
      </a>
    </li>
    <li>
      <a href="/addLearningCenter" className="d-flex align-items-center">
        <Icon icon="mdi:plus-box-outline" className="submenu-icon me-2" />
        Add Learning Center
      </a>
    </li>
  </ul>
</li>

<li className="dropdown">
  <a
    href="#!"
    className="d-flex align-items-center "
    onClick={() => toggleDropdown("crypto_resources_directory")}
  >
    <Icon
      icon="material-symbols:article-outline"
      className="menu-icon me-2"
    />
    <span>Crypto Desources Directory</span>
  </a>
  <ul
    className={`sidebar-submenu list-unstyled ms-3 ${
      openDropdown === "crypto_resources_directory" ? "d-block" : "d-none"
    }`}
  >
    <li>
      <a href="/crypto_resources_directory" className="d-flex align-items-center">
        <Icon icon="ic:outline-view-list" className="submenu-icon me-2" />
        View All Crypto Desources Directory
      </a>
    </li>
    <li>
      <a href="/addcrypto_resources_directory" className="d-flex align-items-center">
        <Icon icon="mdi:plus-box-outline" className="submenu-icon me-2" />
        Crypto Desources Directory
      </a>
    </li>
  </ul>
</li>
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Sidebar;
